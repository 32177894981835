<template>
  <div class="photoTrapFilterForm">
    <form>
      <h4 class="m-0 mt-3">
        Date Range
      </h4>
      <hr class="m-0 mb-3">
      <div class="form-group">
        <label>{{ $t('from') }} - {{ $t('until') }}</label>
        <date-picker
          v-model="filter.dates"
          :shortcuts="shortcuts"
          range
          confirm
          range-separator="-"
          type="date"
          lang="en"
          format="DD-MM-YYYY HH:mm"
          class="w-100"
        />
      </div>
      <div class="clearfix" />
      <div class="form-group float-left">
        <label>Show Object Detected</label>
        <br>
        <toggle-button
          v-model="$parent.$parent.capturedObjects"
          :labels="{ checked: 'true', unchecked: 'false' }"
          :width="70"
          :height="30"
          :font-size="12"
          class="m-0"
        />
      </div>
      <div class="clearfix" />
      <button
        class="btn btn-primary float-right"
        @click.prevent="findItems()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="search"
        />
        <span>{{ $t('search') }}</span>
      </button>
      <div class="clearfix" />
      <div class="form-group float-left">
        <label>Load debug images</label>
        <br>
        <toggle-button
          v-model="$parent.$parent.loadDebugImages"
          :labels="{ checked: 'true', unchecked: 'false' }"
          :width="70"
          :height="30"
          :font-size="12"
          class="m-0"
        />
      </div>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';

export default {
  name: "PhotoTrapFilterForm",
  components: {
    DatePicker
  },
  mixins: [
    dateTimeMixin
  ],
  data () {
    return {
      filter: {
        dates: [this.dateTime_iso(new Date()), new Date()],
        cameraId: 0,
        index: 0,
        range: 0
      },
      options: {
        format: 'YYYY-MM-DD HH:mm',
        showClose: true,
        locale: 'en-gb',
        timeZone: 'Austria/Vienna'
      },
      shortcuts: [
        {
          text: 'Today',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        },
        {
          text: 'Yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        },
        {
          text: 'The day before yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 3, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        }
      ]
    }
  },
  created () {
    this.setDates();
  },
  methods: {
    setDates () {
      let today = this.filter.dates[1];
      let date = today.getFullYear() +'-'+ (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.filter.dates[1] = date +' '+ time;
    },
    findItems () {
      this.$emit('findItems', this.filter);
    }
  }
}
</script>

<style>
.modal {
  z-index: 999;
}
.sidebar .btn.btn-secondary.btn-sm {
  z-index: 999;
}
</style>
